


import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  Grid,
  Link,
} from '@mui/material';

import LinksNavbar from './LinksNavbar';

function CreatorAppBar({ text, backGround, getEndpoint }) { // funcion para actulizar el json va a pasr a linksappbar

  const NAV_ITEMS = [
    { tag: "TAG_STORE", title: "Productos", link: "/landing/store", submenu: true, display: true },
    { tag: "TAG_ABOUT", title: "Nosotros", link: "/landing/about", display: true },
    { tag: "TAG_CATALOG", title: "Catálogo", link: "/landing/catalog", submenu: true, display: true },
    { tag: "TAG_SERVI", title: "Servicios", link: "/landing/services", display: true },
    { tag: "TAG_LOCATION", title: "Location", link: "/landing/location", display: true },
  ];

  //Recibimos el endpoint actual
  const currentEndpoint = getEndpoint() || 'default/endpoint'; // Usa un valor por defecto si es necesario

  useEffect(() => {
    console.log('Current Endpoint:', currentEndpoint);
  }, [currentEndpoint]);


  return (
    <Box>
      <AppBar
        component="nav"
        sx={{
          backgroundColor: '#fff',
          maxWidth: "100vw",
          borderTop: 60,

        }}
      >
        <Toolbar
          sx={{

          }}
        >
          <Container maxWidth="xl">
            <Toolbar
              sx={{
                margin: 0,
                "&.MuiToolbar-root": {
                  padding: 0,
                },
              }}
            >
              <Grid
                container
                sx={{ justifyContent: { xs: "space-between", } }}
              >
                <LinksNavbar
                  navItems={NAV_ITEMS}
                  text={'Black'}
                  icon={true}
                  getEndpoint={getEndpoint}  // Pasa getEndpoint aquí
                />
              </Grid>


            </Toolbar>
          </Container>

        </Toolbar>
      </AppBar>
    </Box>
  );
}

// App title in appbar
function TitlePage(props) {
  const { navLanding, favicon } = props;


  return (
    <Link href={navLanding ? "/" : "/"}>
      <Box
        component="img"
        alt="keski Nube icon"
        src={favicon ? favicon : favicon}
        sx={{ display: "flex", width: { xs: "120px", sm: "120px" } }}
      />
    </Link>
  );
}

export default CreatorAppBar;

