import HomeIcon from '@mui/icons-material/Home';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import PeopleIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppsIcon from "@mui/icons-material/Apps";
import StoreIcon from '@mui/icons-material/Store';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import InfoIcon from '@mui/icons-material/Info';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FeedbackIcon from '@mui/icons-material/Feedback';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import admin from './images/administracion.png'
import landing from './images/keskicreator.png'
import catalog from './images/catalogo.png'
import onlinestore from './images/tienda_linea.png'
import inventory from './images/gestion_inventario.png'
import posAdmin from './images/gestor_punto_venta.png'
import pos from './images/punto_venta.png'
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PercentIcon from '@mui/icons-material/Percent';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { CreateMenu } from './dynamicMenus';

var appsWozapp = [
    {
        id: 1,
        images: admin,
        alt: 'administration',
        title: 'Administración',
        name: 'administration',
        text: 'Administra tu negocio y agrega nuevos usuarios y sucursales.',
        notHome: true,
        notFinish: false,
        menu: [
            {
                text: 'Mis Aplicaciones',
                gohome: true,
                icon: <AppsIcon sx={{ color: 'text.secondary' }} />,
                url: '/home',

            },
            {
                text: 'Inicio',
                icon: <HomeIcon sx={{ color: 'text.secondary' }} />,
                url: 'home',
                notfinish: true,
            },
            {
                text: 'Usuarios',
                icon: <PeopleIcon sx={{ color: 'text.secondary' }} />,
                url: 'users',
            },
            {
                text: 'Mi negocio',
                icon: <StoreMallDirectoryIcon sx={{ color: 'text.secondary' }} />,
                url: 'business',
            },
            {
                text: 'Sucursales',
                icon: <AddBusinessIcon sx={{ color: 'text.secondary' }} />,
                url: 'branch',
            },
            /*             {
                            text:'Políticas',
                            icon: <AssignmentIcon sx={{color: 'text.secondary'}}/>,
                            url:'politics',
                        }, */
            {
                text: 'Configuración',
                icon: <SettingsIcon sx={{ color: 'text.secondary' }} />,
                url: 'settings',

            },

        ],
    },
    {
        id: 3,
        images: catalog,
        alt: 'Catálogo de artículos',
        title: 'Catálogo de artículos',
        name: 'catalog',
        text: 'Gestiona los artículos, materia prima e insumos que se utilizan en tu negocio.',
        notHome: true,
        notFinish: false,
        menu: [
            {
                text: 'Mis Aplicaciones',
                gohome: true,
                icon: <AppsIcon sx={{ color: 'text.secondary' }} />,
                url: '/home',
            },
            {
                text: 'Inicio',
                icon: <HomeIcon sx={{ color: 'text.secondary' }} />,
                url: 'home',
                notfinish: true,
            },
            {
                text: 'Artículos',
                icon: <InventoryIcon sx={{ color: 'text.secondary' }} />,
                url: 'articles',
            },
            {
                text: 'Configuración',
                icon: <SettingsIcon sx={{ color: 'text.secondary' }} />,
            }

        ],

    },
    {
        id: 2,
        images: landing,
        alt: 'Landing',
        title: 'Creador Sitio Web',
        name: 'landing',
        text: 'Mantén al día la información de tu negocio para que tus clientes te conozcan mejor.',
        notHome: true,
        notFinish: false,
        menu: [
            {
                text: 'Mis Aplicaciones',
                gohome: true,
                icon: <AppsIcon sx={{ color: 'text.secondary' }} />,
                url: '/home',
            },
            {
                text: 'Inicio',
                icon: <HomeIcon sx={{ color: 'text.secondary' }} />,
                url: 'home',
                notfinish: true,
            },
            {
                text: 'Metadata',
                icon: <HomeIcon sx={{ color: 'text.secondary' }} />,
                url: 'meta-data',
            },
            {
                text: 'Keski Creator',
                icon: <NewspaperIcon sx={{ color: 'text.secondary' }} />,
                /*  nestedMenu: [
                     {
                         text:'Landing Nosotros',
                         url: 'lnd-about',
                     },
                     {
                         text:'Landing Tienda',
                         url: 'lnd-store'
                     },  
                 ], */
                nestedMenu: [],
                dynamicMenu: CreateMenu,
            },
            {
                text: 'Políticas',
                icon: <ArticleIcon sx={{ color: 'text.secondary' }} />,
                url: 'politics',
            },

            /*             {
                            text:'Contacto',
                            icon: <ContactMailIcon sx={{color: 'text.secondary'}}/>,
                        }, 
                        {
                            text:'Servicios',
                            icon: <MiscellaneousServicesIcon sx={{color: 'text.secondary'}}/>,
                        }, 
                        {
                            text:'Sobre nosotros',
                            icon: <FeedbackIcon sx={{color: 'text.secondary'}}/>,
                        }, 
                        {
                            text:'Configuración',
                            icon: <SettingsIcon sx={{color: 'text.secondary'}}/>,
                        },  */
        ],

    },
    {
        id: 5,
        images: inventory,
        alt: 'Inventario',
        title: 'Gestor de Inventario',
        name: 'inventory',
        text: 'Conoce cuanto y donde tienes tus artículos disponles para venta, compra y transferencia.',
        notHome: true,
        notFinish: false,
        menu: [
            {
                text: 'Mis Aplicaciones',
                gohome: true,
                icon: <AppsIcon sx={{ color: 'text.secondary' }} />,
                url: '/home',

            },
            {
                text: 'Inicio',
                icon: <HomeIcon sx={{ color: 'text.secondary' }} />,
                url: 'home',
                notfinish: true,
            },
            {
                text: 'Compras y pagos',
                icon: <ArrowCircleRightOutlinedIcon sx={{ color: 'text.secondary' }} />,
                nestedMenu: [
                    {
                        text: 'Proveedores',
                        url: 'suppliers',
                    },
                    {
                        text: 'Compras',
                        url: 'purchases'
                    },
                ]
            },
            {
                text: 'Inventario',
                icon: <InventoryOutlinedIcon sx={{ color: 'text.secondary' }} />,
                nestedMenu: [
                    {
                        text: 'Almacén',
                        url: 'warehouse',
                    },
                    {
                        text: 'Inventario',
                        url: 'inventory'
                    },
                ]
            },
            {
                text: 'Ventas y cobranza',
                icon: <ArrowCircleLeftOutlinedIcon sx={{ color: 'text.secondary' }} />,
                nestedMenu: [
                    {
                        text: 'Clientes',
                        url: 'customers',
                    },
                    {
                        text: 'Ventas',
                        url: 'sales'
                    },
                ]
            },
            /*{
                text: 'Producción',
                icon: <CategoryOutlinedIcon sx={{ color: 'text.secondary' }} />,
                nestedMenu: [
                    {
                        text: 'Transformación',
                        url: 'transformation',
                    },
                    {
                        text: 'Recetas',
                        url: 'recipes'
                    },
                ]
            },
*/
            {
                text: 'Configuración',
                icon: <SettingsIcon sx={{ color: 'text.secondary' }} />,
                url: 'settings',
            },
        ],

    },

    {
        id: 7,
        images: posAdmin,
        alt: 'POS view',
        title: 'Gestor punto de venta',
        name: 'pos-admin',
        text: 'Gestiona y revisa en tiempo real el inventario y ventas de cada sucursal.',
        menu: [
            {
                text: 'Mis Aplicaciones',
                gohome: true,
                icon: <AppsIcon sx={{ color: 'text.secondary' }} />,
                url: '/home',

            },
            /*{
                text: 'Inicio',
                icon: <HomeIcon sx={{ color: 'text.secondary' }} />,
                url: "home",

            },*/
            {
                text: 'Cajas',
                icon: <PointOfSaleIcon sx={{ color: 'text.secondary' }} />,
                url: "cash-register",
            },
            {
                text: 'Descuentos',
                icon: <PercentIcon sx={{ color: 'text.secondary' }} />,
                url: "discounts",
            },
            {
                text: 'Productos',
                icon: <InventoryIcon sx={{ color: 'text.secondary' }} />,
                url: "products",
            },
            {
                text: 'Configuración',
                icon: <SettingsIcon sx={{ color: 'text.secondary' }} />,
                url: "settings"
            },


        ],

    },
    {
        id: 4,
        images: onlinestore,
        alt: 'Tienda en línea inventario',
        title: 'Gestor tienda en línea',
        name: 'online-store',
        text: 'Gestiona y actualiza  los pedidos de tus clientes y realiza los envíos.',
        notHome: true,
        notFinish: false,
        menu: [
            {
                text: 'Mis Aplicaciones',
                gohome: true,
                icon: <AppsIcon sx={{ color: 'text.secondary' }} />,
                url: '/home',
            },
            {
                text: 'Inicio',
                icon: <HomeIcon sx={{ color: 'text.secondary' }} />,
                url: 'home',
                notfinish: true,
            },
            {
                text: 'Pedidos',
                icon: <InventoryOutlinedIcon sx={{ color: 'text.secondary' }} />,
                url: 'orders'
            },
            /*         {
                        text:'Información de mi página',
                        icon: <InventoryOutlinedIcon sx={{color: 'text.secondary'}}/>,
                        url:'store-info',
                    },
                    {
                        text:'Keski Creator',
                        icon: <InventoryOutlinedIcon sx={{color: 'text.secondary'}}/>,
                        url:'keski-creator',
                    }, */

            /*         {
                        text:'Landing',
                        icon: <NewspaperIcon sx={{color: 'text.secondary'}}/>,
                        nestedMenu: [
                
               
                            {
                                text:'Información de mi página',
                                url:'store-info',
                            },
            
                            {
                                text:'Keski Creator',
                                url:'keski-creator',
                            },
            
            
                        ]
                    },  */

            {
                text: 'Clientes',
                icon: <PeopleIcon sx={{ color: 'text.secondary' }} />,

                url: 'customers'
            },

            {
                text: 'Puntos de venta',
                icon: <AddBusinessIcon sx={{ color: 'text.secondary' }} />,
                url: 'pos'
            },
            {
                text: 'Productos',
                icon: <InventoryIcon sx={{ color: 'text.secondary' }} />,
                url: 'products'
            },
            {
                text: 'Configuración',
                icon: <SettingsIcon sx={{ color: 'text.secondary' }} />,
                nestedMenu: [
                    {
                        text: 'Estado de mi página',
                        url: 'store-status',
                    },
                    {
                        text: 'Reglas de operación',
                        url: 'operation-rules',
                    },

                    {
                        text: 'Envíos',
                        url: 'shipments',
                    },
                    {
                        text: 'Pagos',
                        url: 'payments',
                    },
                    {
                        text: 'Generales',
                        url: 'general',
                    },


                ]
            },
        ],

    },
    {
        id: 6,
        images: pos,
        alt: 'Punto de venta',
        title: 'Punto de venta',
        name: 'cashRegister',
        text: 'Vende tus artículos desde cualquier sucursal desde el punto de venta web o móvil.',
        notHome: true,
        notFinish: false,
        menu: [
            {
                text: 'Inicio',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                url: '/home?tab=2'

            },
            {
                text: 'Movimientos en efectivo',
                icon: null,
            },
            {
                text: 'Caja registradora',
                icon: null,
                url: 'pointofsale/cash-register/undefined/warehouse/undefined/undefined',
            },
            {
                text: 'Cerrar caja',
                icon: null,

            },



        ],

    },

    {
        id: 8,
        name: 'user detail',
        menu: [
            {
                text: 'Usuarios',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 1,
                url: -1,
            },
            {
                text: 'Perfil',
                icon: <AssignmentIndIcon sx={{ color: 'text.secondary' }} />,
                url: null,
            }
        ],

    },
    {
        id: 9,
        name: 'branch detail',
        menu: [
            {
                text: 'Sucursales',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 1,
                url: -1,
            },
            {
                text: 'Tienda',
                icon: <StoreIcon sx={{ color: 'text.secondary' }} />,
                url: null,
            }
        ],

    },
    {
        id: 10,
        name: 'catalog',
        title: 'Artículo',
        menu: [
            {
                text: 'Artículos',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 3,
                url: -1  /* "articles" */,
            },
            {
                text: 'Detalles',
                icon: null,
                url: "/catalog/articles/detail",
            },
            {
                text: 'Filtros',
                icon: null,
                url: "/catalog/articles/filter",
            }
        ],

    },
    {
        id: 11,
        name: 'detalle compra',
        menu: [
            {
                text: 'Ordenes de Compra',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],


    },
    {
        id: 12,
        name: 'Almacén detalle compra',
        menu: [
            {
                text: 'Almacén',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 13,
        name: 'Almacén detalle venta',
        menu: [
            {
                text: 'Almacén',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 14,
        name: 'Almacén detalle venta',
        menu: [
            {
                text: 'Recetas',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 15,
        name: 'Orden de producción',
        menu: [
            {
                text: 'Orden de producción',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 16,
        name: 'Orden de desecho',
        menu: [
            {
                text: 'Orden de desecho',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 17,
        name: 'Orden de ajuste',
        menu: [
            {
                text: 'Ajuste de inventario',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 18,
        name: 'Proveedor',
        menu: [
            {
                text: 'Proveedor',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: "suppliers",
            },
            {
                text: 'Detalles',
                icon: null,
                url: "suppliers/detail",
            },
            {
                text: 'Artículos',
                icon: null,
                url: 'suppliers/detail/articles',
            }
        ],

    },
    {
        id: 19,
        name: 'Orden de venta',
        menu: [
            {
                text: 'Venta',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 20,
        name: 'Orden de compra',
        menu: [
            {
                text: 'Compra',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 21,
        name: 'Cajas',
        menu: [
            {
                text: 'Cajas',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 7,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            },
        ],

    },
    {
        id: 22,
        name: 'Cliente',
        menu: [
            {
                text: 'Cliente',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },

    {
        id: 23,
        name: 'Administrar almacén',
        menu: [
            {
                text: 'Almacenes',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1 - 1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 24,
        name: 'Orden de Transferencia',
        menu: [
            {
                text: 'Orden de transferencia',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 5,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 25,
        name: 'Orden de Transferencia',
        menu: [
            {
                text: 'Pedidos',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 4,
                url: -1,
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 26,
        name: 'Keski creator',
        menu: [
            {
                text: 'Editor',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 4,
                url: "/online-store/keski-creator",
            },
            {
                text: 'Detalles',
                icon: null,
                url: null,
            },
            {
                text: 'Imágenes',
                icon: null,
                url: null,
            }
        ],

    },
    {
        id: 27,
        name: 'Keski creator',
        menu: [
            {
                text: 'Editor',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 2,
                url: "/landing/store",
            },
            {
                text: 'Detalles',
                icon: null,
                url: "/landing/store/detail",
            },
            {
                text: 'Imágenes',
                icon: null,
                url: "/landing/image",
            }
        ],

    },
    {
        id: 28,
        name: 'Keski creator',
        menu: [
            {
                text: 'Editor',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 2,
                url: "/landing/about",
            },
            {
                text: 'Detalles',
                icon: null,
                url: "/landing/about/detail",
            },
            {
                text: 'Imágenes',
                icon: null,
                url: "/landing/image",
            }
        ],

    },
    {
        id: 29,
        name: 'Keski creator',
        menu: [
            {
                text: 'Editor',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 2,
                url: "/landing/catalog",
            },
            {
                text: 'Detalles',
                icon: null,
                url: "/landing/catalog/detail",
            },
            {
                text: 'Imágenes',
                icon: null,
                url: "/landing/image",
            }
        ],

    },
    {
        id: 30,
        name: 'Keski creator',
        menu: [
            {
                text: 'Editor',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 2,
                url: "/landing/services",
            },
            {
                text: 'Detalles',
                icon: null,
                url: "/landing/services/detail",
            },
            {
                text: 'Imágenes',
                icon: null,
                url: "/landing/image",
            }
        ],

    },
    {
        id: 31,
        name: 'Keski creator',
        menu: [
            {
                text: 'Editor',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 2,
                url: "/landing/blog",
            },
            {
                text: 'Detalles',
                icon: null,
                url: "/landing/blog/detail",
            },
            {
                text: 'Imágenes',
                icon: null,
                url: "/landing/image",
            }
        ],


    },

    /* ESTE SERA EL DE TAG_HOME*/
    {
        id: 33,
        name: 'Keski creator',
        menu: [
            {
                text: 'Editor',
                icon: <ArrowBackIcon sx={{ color: 'text.secondary' }} />,
                comeback: 2,
                url: "/landing/home",
            },
            {
                text: 'Detalles',
                icon: null,
                url: "/landing/home/detail",
            },
            {
                text: 'Imágenes',
                icon: null,
                url: "/landing/image",
            }
        ],

    },
    {
        id: 34,
        images: pos,
        alt: 'Inventario',
        title: 'Inventario',
        name: 'posinventory',
        text: 'Revis el inventario en tu sucursal',
        notHome: true,
        notFinish: true,
        menu: [
            {
                text: 'Inicio',
                icon: null,

            },
            {
                text: 'Pedidos',
                icon: null,
            },
            {
                text: 'Pedidos',
                icon: null,
                url: 'inventory',
            },


        ],

    },
    {
        id: 35,
        images: pos,
        alt: 'Transferencias',
        title: 'Transferencias',
        name: 'pos-transfers',
        text: 'Revisa la transferencia entre sucursales',
        notHome: true,
        notFinish: true,
        menu: [
            {
                text: 'Inicio',
                icon: <HomeIcon sx={{ color: 'text.secondary' }} />,

            },
            {
                text: 'Pedidos',
                icon: <PeopleIcon sx={{ color: 'text.secondary' }} />,
            },
            {
                text: 'Pedidos',
                icon: <PeopleIcon sx={{ color: 'text.secondary' }} />,
                url: 'transfers',
            },


        ],

    },
    /* POSIntegration This section has been added to have the pos integrated in the admin project */


];

export function getAppsData() {
    return appsWozapp;
}
export function getAppsCars() {
    return appsWozapp.filter((app) => app.id < 8);
}
{/* POSIntegration This section has been added to have the pos integrated in the admin project */ }
export function getAppsPos() {
    return appsWozapp.filter((app) => app.id === 33);
}
{/* POSIntegration This section has been added to have the pos integrated in the admin project */ }

export function getData(id) {
    return appsWozapp.find((app) => app.id === id);
}

export function getDataMenu(name) {
    return appsWozapp.find((app) => app.name === name);
}

